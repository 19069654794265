<svelte:options tag="fds-checkbox"></svelte:options>
<script>
    import { onMount,createEventDispatcher } from 'svelte'
    import {get_current_component} from "svelte/internal"
    let host = get_current_component()
    import fdsHelper from "@fds-components/fds-helper"
    let path = fdsHelper.get_href()
    import { version as componentversion,name as  cname} from '../package.json';


    let componentready = false;
    let svelteDispatch = createEventDispatcher();
    const dispatch = (name, detail) => {
        svelteDispatch(name, detail)
        host.dispatchEvent && host.dispatchEvent(new CustomEvent(name, { detail }))
    }





    /**
     * Checkbox  component is clicked
     * @event click
     */


    /**
     * Checkbox  component is mouseover
     * @event mouseover
     */

     /**
     * Checkbox  component ismouseleave
     * @type mouseleave
     */

    /**
     * Checkbox is check uncheck
     * @event {boolean} check
     */

    /**
     * Checkbox change its value
     * @event {boolean} change
     */

    /**
     * Specify whether the checkbox is checked
     * @type {boolean}
     */
     export let checked = false;



    /**
     * Set to `true` for the checkbox to be read-only
     * @type {boolean}
     */
    export let readonly = false;

     /**
     * Set to `true` to disable the checkbox
     * @type {boolean}
     */
    export let disabled = false;


    /**
     * Specify the label text
     * @type {string}
     */
    export let labeltext = "";
    export let animated = false;

    /**
     * Set to `true` to visually hide the label text
     * @type {boolean}
     */
    export let hidelabel = false;



    /**
     * Specify the title attribute for the label element
     * @type {string}
     */
    export let title = undefined;



    /**
     * Obtain a reference to the input HTML element
     * @type {HTMLElement}
     */

    export let ref = null;

    /**
     * The name of element if checkbox is active state
     * @type {string}
     */
    export let item_on = "";
    /**
     *  The name of element if checkbox is inactive state
     * @type {string}
     */
    export let item_off = "";


    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type==="version"){
            return new Promise(resolve => {
                resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(type,cname);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;

    /*
    * Specify the value of the checkbox
    * @type {any}
    */
    export let value = "";
    /* Set a name for the input element */
    let name = "";
    /* Set an id for the input label */
    let id = "ccs-" + Math.random().toString(36);

    let readonly_str='false'

    /* Specify whether the checkbox is indeterminate */
    let indeterminate = false;

    onMount(()=> {
        componentready = true;
    })
    $: if(checked=='false'){checked=false;}
    $: checked=!!checked;
    $: dispatch("check", checked);
    $: dispatch("change", value);
    $: if (checked && item_on) value = item_on;
    $: if (!checked && item_off) value = item_off;
    $: if(readonly=='true' || readonly === true ){
        readonly_str='true'} else{
            readonly_str='false'
    }
    $: if(disabled || readonly )  animated = false;

</script>

{#if componentready}
    <div
            class:checkbox-wrapper="{true}"
            class:animated="{animated}"
            {...$$restProps}
            style={'position: relative;'}
            on:click
            on:mouseover
            on:mouseenter
            on:mouseleave
    >

        {#if readonly_str == 'true'}
            {#if checked}
                <fds-icon style="position: absolute; top: 4px; left: 4px;" name="check" set="fas" height="15"></fds-icon>
            {/if}
            <input
            bind:this="{ref}"
            type="hidden"
            value="{value}"
            id="{id}"
            indeterminate="{indeterminate}"
            name="{name}"
            class:checkbox="{true}"
            />
        {:else}
        <input
            bind:this="{ref}"
            type="checkbox"
            value="{value}"
            checked="{checked}"
            disabled="{disabled}"
            id="{id}"
            indeterminate="{indeterminate}"
            name="{name}"
            class:task-check="{true}"
            class:checkbox="{true}"
            on:change="{() => {
            checked = !checked;
            }}"
                    on:blur
            />
        {/if}
        <label for="{id}" title="{title}" class:task-check-label="{animated}"  class:animated="{animated}" class:checkbox-label="{true}">
        {#if animated}
            <span class="check"></span>
            <span class="box"></span>
        {/if}
      <span
              class:checkbox-label-text="{true}"
              class:visually-hidden="{hidelabel}"
      >
        <slot name="labelText">
          {@html labeltext}
        </slot>
      </span>
        </label>
    </div>
{/if}
<style>
    .checkbox-wrapper {

        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: flex-start;
        color: var(--text-color,#161616);
    }
    .checkbox {
        position: absolute;
        overflow: hidden;
        width: 1px;
        height: 1px;
        padding: 0;
        border: 0;
        margin: -1px;
        clip: rect(0,0,0,0);
        visibility: inherit;
        white-space: nowrap;
    }
    .checkbox-label {
        font-size: var(--label-font-size,.875rem);
        font-weight: var(--label-font-weight,400);
        line-height: var(--label-line-height,1.29);
        letter-spacing: var(--label-letter-spacing,.16px);
        position: relative;
        display: flex;
        min-height: 1.5rem;
        padding-top: 0.1875rem;
        padding-left: 1.25rem;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .checkbox-label span{
        color: var(--text-color,#161616);
    }

    .checkbox-label:not(.animated)::before {
        position: absolute;
        top: 0.125rem;
        left: 0;
        width: 1rem;
        height: 1rem;
        border: 1px solid var(--labelborder,#161616);
        margin: 0.125rem 0.125rem 0.125rem 0.1875rem;
        background-color: var(--checkbox-bkg,transparent);
        border-radius: 1px;
        content: "";
        box-sizing: border-box;
    }

    .checkbox-label-text {
        padding-left: 0.375rem;
    }

    .checkbox-label:not(.animated)::after {
        position: absolute;
        top: 0.3rem;
        left: 0.4375rem;
        width: 0.5625rem;
        height: 0.3125rem;
        border-bottom: 2px solid var(--selectcolor,black);
        border-left: 2px solid var(--selectcolor,black);
        margin-top: -0.1875rem;
        background: 0 0;
        content: "";
        -webkit-transform: scale(0) rotate(
                -45deg);
        transform: scale(0) rotate(
                -45deg);
        -webkit-transform-origin: bottom right;
        transform-origin: bottom right;
    }


    .checkbox:disabled + .checkbox-label::after {
        border-bottom: 2px solid var(--color-disabled,#c6c6c6);
        border-left: 2px solid var(--color-disabled,#c6c6c6);
    }


    .checkbox:checked + .checkbox-label:not(.animated)::after {
        -webkit-transform: scale(1) rotate(-45deg);
        transform: scale(1) rotate(-45deg);
    }

    .checkbox:disabled + .checkbox-label span {
        color: var(--color-disabled,#c6c6c6);
        cursor: not-allowed;
    }

    .checkbox:disabled + .checkbox-label::before {
        border-color: var(--color-disabled,#c6c6c6);
    }



    .task-check-label .box, .task-check-label .check{
        display: block;
        position: absolute;
        left: 0;
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }

    .task-check-label .check {
        top: -7px;
        left: 6px;
        width: 12px;
        height: 24px;
        border: 2px solid #0f9d58;
        border-top: none;
        border-left: none;
        opacity: 0;
        /*z-index: 888;*/
        -webkit-transform: rotate(
                180deg
        );
        -moz-transform: rotate(180deg);
        transform: rotate(
                180deg
        );
        -webkit-transition-delay: 0.3s;
        -moz-transition-delay: 0.3s;
        transition-delay: 0.3s;
    }

    .task-check-label .box {
        border: 2px solid #000;
        height: 20px;
        width: 20px;
        /*z-index: 888;*/
        -webkit-transition-delay: 0.2s;
        -moz-transition-delay: 0.2s;
        transition-delay: 0.2s;
    }

    input[type="checkbox"] {
        display: none;
    }

    .task-check:checked ~ label .box {
        opacity: 0;
        -webkit-transform: scale(0) rotate(-180deg);
        -moz-transform: scale(0) rotate(-180deg);
        transform: scale(0) rotate(-180deg);
    }

    .task-check:checked ~ label .check {
        opacity: 1;
        -webkit-transform: scale(1) rotate(45deg);
        -moz-transform: scale(1) rotate(45deg);
        transform: scale(1) rotate(45deg);
    }





</style>